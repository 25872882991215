import React from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import MainFrontpage from "../../components/MainFrontpage";
import AnalyticsLeftColumn from "../../components/AnalyticsLeftColumn";
import AnalyticsRightColumn from "../../components/AnalyticsRightColumn";
import { useAlternateLangs } from "../../components/Hreflangs";

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/analytics"
);


const Analytics = ({ location }) => { 
  return (<Layout location={location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Tutorials over web analytics, GA & Google Tag Manager"
        lang="nl"
        description="Leer de basis- en geavanceerde concepten van web analytics, Google Analytics & GTM met stapsgewijze tutorials. Verbeter je marketingprestaties met bruikbare inzichten!"
        image="homepage-screenshot"
        alternateLangs={alternateLangs}
        canonical="/nl/analytics"
      />
      <MainFrontpage>
        <AnalyticsLeftColumn />
        <AnalyticsRightColumn />
      </MainFrontpage>
    </React.Fragment>

  </Layout>
  );
};

export default React.memo(Analytics);
